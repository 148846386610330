<template ref="fullscreenElement">
  <transition name="fade">
      <el-row :gutter="10" v-if="!state.loaderEnabled" :class="state.classTest" style="width: 100%;">
        <el-col :span="16">

          <div class="card"  style="width: 100%;">
            <div class="card-header border-0 pt-6">
              <div class="card-title" style="width:100%;">
                <h2>Test Technique : {{ state.myTest.tst_libelle }}</h2>
              </div>
            </div>

            <div class="card-body pt-0">
              <hr>

              <div class="alert bg-light-primary alert-primary d-flex align-items-center p-5 mb-10">
                <span class="svg-icon svg-icon-2hx svg-icon-primary me-4">
                  <inline-svg src="media/icons/duotune/general/gen048.svg" />
                </span>
                <div class="d-flex flex-column">
                  <h4 class="mb-1 text-dark">Chaque question peut avoir une ou plusieurs réponses possibles.</h4>
                  <span>Veillez à bien sélectionner chaque case à cocher correspondante.</span>
                  <hr>
                  <span class="mb-1 text-dark">Attention, l'ouverture d'un nouvel onglet sur votre navigateur ou le retour sous windows peut annuler le test...</span>
                </div>
              </div>

              <template v-for="item in state.quiz" :key="item.id">
                <div v-if="item.display">
                  <h3 class="mt-10 text-primary text-uppercase"><img :src="state.categ.filter(ref => ref.id == item.categ)[0].icon" style="max-width: 30px; max-height: 20px" class="mx-3" /><b>{{ state.categ.filter(ref => ref.id == item.categ)[0].label }}</b></h3>
                  <h4 class="mt-10 mb-5 text-black unselectable">{{ item.question }}</h4>
                  

                  <pre v-if ="item.code" class="language-js">
                    <code class="language-js unselectable">{{ item.code }}</code>
                  </pre>

                  <CodeHighlighter lang="js" :fieldHeight="0" class="d-none unselectable"></CodeHighlighter>
                  
                  <h4 class="mt-10 mb-5 text-black unselectable">Votre réponse :</h4>


                  <template v-for="rep in item.reponses" :key="rep.label">
                    <div class="form-check form-check-custom form-check-solid mb-2 chcbox p-2" style="align-items: center;" @click="setReponse(item.id, rep.id, !rep.chxUser); rep.chxUser = !rep.chxUser">
                      <input class="form-check-input" type="checkbox" v-model="rep.chxUser" />
                      <div class="ms-3 form-text text-dark fs-4 m-0 unselectable">{{ rep.label }}</div>
                    </div>
                  </template>

                  

                  <br>
                  <div style="float:right" class="mt-10 mb-10">
                    <div class='btn btn-lg btn-secondary mx-2' v-if="state.quiz.findIndex(ind => ind.id === item.id) > 0" @click='prevQuestion(item.id)'>⬅️ Question précédente</div>
                    <div class='btn btn-lg btn-primary' v-if="state.quiz.findIndex(ind => ind.id === item.id) < state.quiz.length-1" @click='nextQuestion(item.id)'>Question suivante ➡️</div>
                    <div class='btn btn-lg btn-primary' v-else @click='final(item.id)'>Finaliser le test 🎯</div>
                  </div>
                </div>
            </template>

            </div>
          </div>

        </el-col>
        <el-col :span="8">
          
          <EyeTracking class='d-none'/>

          <div class="card" v-if="!state.loaderEnabled" style="width: 100%;">
            <div class="card-header border-0 pt-6">
              <div class="card-title" style="width:100%;">
                <h2>{{$t("Progression")}}  - <i>{{ state.total_reponse }} réponse{{ state.total_reponse > 1 ? "s" : "" }} donnée{{ state.total_reponse > 1 ? "s" : "" }} sur {{ state.quiz.length }} questions</i></h2>
              </div>
            </div>

            <div class="card-body pt-0">
              <hr>

              <div class="demo-collapse">
                <el-collapse v-model="state.activeCateg">
                  <el-collapse-item :title="item.label" :name="item.id" v-for="item in state.categ" :key="item.id">
                    
                    <el-row :gutter="20" class="selectRowCat p-2" :class="{'bg-secondary': state.currentQuestion==question.id}" @click="clickQuestion(question.id)" v-for="question in state.quiz.filter(ref => ref.categ==item.id)" :key="question.id">
                      <el-col :span="20" >{{ question.question }}</el-col>
                      <el-col :span="4">
                        <svg v-if="question.valid" style='width: 20px' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M64 80c-8.8 0-16 7.2-16 16l0 320c0 8.8 7.2 16 16 16l320 0c8.8 0 16-7.2 16-16l0-320c0-8.8-7.2-16-16-16L64 80zM0 96C0 60.7 28.7 32 64 32l320 0c35.3 0 64 28.7 64 64l0 320c0 35.3-28.7 64-64 64L64 480c-35.3 0-64-28.7-64-64L0 96zM337 209L209 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L303 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" /></svg>
                        <svg v-else style='width: 20px' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M384 80c8.8 0 16 7.2 16 16l0 320c0 8.8-7.2 16-16 16L64 432c-8.8 0-16-7.2-16-16L48 96c0-8.8 7.2-16 16-16l320 0zM64 32C28.7 32 0 60.7 0 96L0 416c0 35.3 28.7 64 64 64l320 0c35.3 0 64-28.7 64-64l0-320c0-35.3-28.7-64-64-64L64 32z"/></svg>
                      </el-col>
                    </el-row>

                  </el-collapse-item>
                </el-collapse>
              </div>
           
              
            </div>
          </div>

        </el-col>
      </el-row>
  </transition>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, reactive, onUnmounted } from "vue";

import mAxiosApi from "@/api";
import { useI18n } from "vue-i18n";
import CodeHighlighter from "@/components/highlighters/CodeHighlighter.vue";
import EyeTracking from "@/views/still/deploy/eyetracking.vue"
import { useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { ElMessage, ElMessageBox } from 'element-plus'

export default defineComponent({
  name: "Overview",
  props: {
    idTest: Number,    
  },
  components: {
    CodeHighlighter,EyeTracking
  },
  
  emits: ["retour"],

  setup( props, { emit }) {

    const router = useRouter();
    
    const { t } = useI18n()
    const state = reactive({
      
      quiz : [] as any,
      categ : [] as any,
      activeCateg:0,
      currentQuestion : 0,
      loaderEnabled: true,
      myList: [] as any,
      loadingDatatable: 0,
      total_reponse : 0,
      myTest : [] as any,
      classTest : '',
      message: '',
    });

    const handleVisibilityChange = async () => {
      
      if (document.hidden) {
        state.classTest = 'filter-blur';
        await getAxios("/setNonFocus/" + props.idTest);
      } else {
        state.classTest = 'filter-unblur';
      }
    };

    const handleWindowBlur = async () => {
      state.classTest = 'filter-blur';
      await getAxios("/setNonFocus/" + props.idTest);
    };

    const handleWindowFocus = () => {
      state.classTest = 'filter-unblur';
    };

    onMounted(async () => {

      document.addEventListener('visibilitychange', handleVisibilityChange);
      window.addEventListener('blur', handleWindowBlur);
      window.addEventListener('focus', handleWindowFocus);

      const myTest = await getAxios("/getTest/" + props.idTest);
      state.myTest = myTest.results;

      if(state.myTest.ins_date_fin) {
        state.message = "Vous avez déja soumis le test technique "+ state.myTest.tst_libelle  +", le " + state.myTest.ins_date_fin;

        Swal.fire({
            title: "Attention", 
            text:state.message,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: t("Retour à l'accueil"),
            customClass: {
              confirmButton: "btn fw-bold btn-light-primary",
            },
          }).then(function () {
            emit("retour", 1);
          })

        return
      }

      const myCategs = await getAxios("/getCateg/" + props.idTest);
      state.categ = myCategs.results;

      const getQuiz = await getAxios("/getQuiz/" + props.idTest);
      state.quiz = getQuiz.results;

      
      state.activeCateg = state.quiz[0].categ;
      state.currentQuestion = state.quiz[0].id;
      state.loaderEnabled = false;

    });

    onUnmounted(() => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      window.removeEventListener('blur', handleWindowBlur);
      window.removeEventListener('focus', handleWindowFocus);
      
    });


    const nextQuestion = (currentId) => {
      state.quiz.filter(ref => ref.id == currentId)[0].display = 0;

      const currentIndex = state.quiz.findIndex(item => item.id === currentId);
      if (currentIndex !== -1 && currentIndex + 1 < state.quiz.length) {
        state.quiz[currentIndex + 1].display = 1;
        state.currentQuestion = state.quiz[currentIndex + 1].id;
        state.activeCateg = state.quiz[currentIndex + 1].categ;
      }
      testValid();
    }

    const final = (currentId) => {

      ElMessageBox.confirm('Souhaitez-vous valider et soumettre vos réponses au test ? Il ne sera plus possible de revenir sur le test.','Confirmation',
          { confirmButtonText: 'Soumettre le test', cancelButtonText: 'Annuler', type: 'warning'  }
      )
      .then( async () => {
        nextQuestion(currentId);
        await getAxios("/setFinal/" + props.idTest); 
        Swal.fire({title: "Merci à vous !",  text:'Nous allons analyser vos réponses et revenons vers vous dans quelques minutes. Veuillez patientez svp.', icon: "success", buttonsStyling: false, 
        confirmButtonText: t("Retour à l'accueil"),customClass: { confirmButton: "btn fw-bold btn-light-primary", }}).then(function () {emit("retour", 1);})
      })

    }

    const setReponse = async (idQuest, idReponse, reponse) => {
      await getAxios("/setReponse/" + idQuest + '/' + idReponse + '/' + (reponse ? 1:0));
    }
    


    const prevQuestion = (currentId) => {
      state.quiz.filter(ref => ref.id == currentId)[0].display = 0;

      const currentIndex = state.quiz.findIndex(item => item.id === currentId);
      if (currentIndex !== -1) {
        state.quiz[currentIndex - 1].display = 1;
        state.currentQuestion = state.quiz[currentIndex - 1].id;
        state.activeCateg = state.quiz[currentIndex - 1].categ;
      }
      testValid();
    }

    const testValid = () => {
      state.total_reponse = 0;
      state.quiz.forEach((val) => {
        val.valid = 0;
        val.reponses.forEach((rep) => {        
          if (rep.chxUser) {
            if (val.valid == 0) state.total_reponse ++;
            val.valid = 1;
          }
        })
      })
    }    

    const clickQuestion= (id) => {
      
      state.quiz.forEach((val) => {
        val.display = 0;
      })

      state.quiz.filter(ref => ref.id == id)[0].display = 1;
      state.currentQuestion = id;
      testValid();
    }    


    return {
      state,
      nextQuestion,
      prevQuestion,
      testValid,
      clickQuestion,
      final,
      setReponse
    };
  },
});

export async function getAxios(request: string): Promise<any> {
  const response = await mAxiosApi.get(request);
  return response.data;
}
</script>
