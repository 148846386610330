
import { defineComponent, ref, onMounted, reactive } from "vue";
import store from "@/store";
import * as moment from "moment";
import mAxiosApi from "@/api";
import Test from "@/views/still/deploy/test.vue"
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { formatDateLang } from "@/App.vue";

export default defineComponent({
  name: "Overview",
  components: {
    Test,
  },
  setup() {

    const router = useRouter();

    //moment.default.locale("fr");
    const { t } = useI18n()
    const state = reactive({
      modeSearch : "simple",
      isAuth : store.getters.isUserAuthenticated,
      myList : [] as any,
      currentTest : 0,
    });

   

    const formatDate = (d) => {
      return moment.default(d).format(formatDateLang);
    };
    const formatDateAgo = (d) => {
      return moment.default().diff( d, 'days')*-1 + " " + t("jours");
    };

    onMounted(async () => {  
      const myList = await getAxios("/getTests");
      state.myList = myList.results;
      document.documentElement.requestFullscreen();
    });


    return {
      state,
      formatDate,
      formatDateAgo,
    };
  },
});

export async function getAxios(request: string): Promise<any> {
  const response = await mAxiosApi.get(request);
  return response.data;
}
