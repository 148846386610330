<template>
  <div class="card" style="width: 100%;">
    <div class="card-header border-0 pt-6">
      <div class="card-title" style="width:100%;">
        <h2>EyeTracking</h2>
      </div>
    </div>

    <div class="card-body pt-0">
      <hr>


      <!-- <iframe src="webgaze/index.html"  style="position:fixed; top:0; left:0; bottom:0; right:0; width:100%; height:100%; border:none; margin:0; padding:0; overflow:hidden; z-index:999999;"></iframe> -->
      
      <p>
        Is the user looking at the screen?
        <span :style="{ color: state.isLooking ? 'green' : 'red' }">
          {{ state.isLooking ? 'Yes' : 'No' }}
        </span>
      </p>

    </div>
  </div>

</template>

<script lang="ts">
import { defineComponent, onMounted, reactive } from "vue";


export default defineComponent({
  name: "eyeTracking",
  components: {
    
  },
  
  setup() {

    const state = reactive({
      isLooking: false,
      loaderEnabled: true,
    });


    onMounted(async () => {
      state.loaderEnabled = false;
    });

    return {
      state
    };
  },
});

</script>
