
import { defineComponent, onMounted, reactive } from "vue";


export default defineComponent({
  name: "eyeTracking",
  components: {
    
  },
  
  setup() {

    const state = reactive({
      isLooking: false,
      loaderEnabled: true,
    });


    onMounted(async () => {
      state.loaderEnabled = false;
    });

    return {
      state
    };
  },
});

