
import { defineComponent, ref, onMounted, reactive, onUnmounted } from "vue";

import mAxiosApi from "@/api";
import { useI18n } from "vue-i18n";
import CodeHighlighter from "@/components/highlighters/CodeHighlighter.vue";
import EyeTracking from "@/views/still/deploy/eyetracking.vue"
import { useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { ElMessage, ElMessageBox } from 'element-plus'

export default defineComponent({
  name: "Overview",
  props: {
    idTest: Number,    
  },
  components: {
    CodeHighlighter,EyeTracking
  },
  
  emits: ["retour"],

  setup( props, { emit }) {

    const router = useRouter();
    
    const { t } = useI18n()
    const state = reactive({
      
      quiz : [] as any,
      categ : [] as any,
      activeCateg:0,
      currentQuestion : 0,
      loaderEnabled: true,
      myList: [] as any,
      loadingDatatable: 0,
      total_reponse : 0,
      myTest : [] as any,
      classTest : '',
      message: '',
    });

    const handleVisibilityChange = async () => {
      
      if (document.hidden) {
        state.classTest = 'filter-blur';
        await getAxios("/setNonFocus/" + props.idTest);
      } else {
        state.classTest = 'filter-unblur';
      }
    };

    const handleWindowBlur = async () => {
      state.classTest = 'filter-blur';
      await getAxios("/setNonFocus/" + props.idTest);
    };

    const handleWindowFocus = () => {
      state.classTest = 'filter-unblur';
    };

    onMounted(async () => {

      document.addEventListener('visibilitychange', handleVisibilityChange);
      window.addEventListener('blur', handleWindowBlur);
      window.addEventListener('focus', handleWindowFocus);

      const myTest = await getAxios("/getTest/" + props.idTest);
      state.myTest = myTest.results;

      if(state.myTest.ins_date_fin) {
        state.message = "Vous avez déja soumis le test technique "+ state.myTest.tst_libelle  +", le " + state.myTest.ins_date_fin;

        Swal.fire({
            title: "Attention", 
            text:state.message,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: t("Retour à l'accueil"),
            customClass: {
              confirmButton: "btn fw-bold btn-light-primary",
            },
          }).then(function () {
            emit("retour", 1);
          })

        return
      }

      const myCategs = await getAxios("/getCateg/" + props.idTest);
      state.categ = myCategs.results;

      const getQuiz = await getAxios("/getQuiz/" + props.idTest);
      state.quiz = getQuiz.results;

      
      state.activeCateg = state.quiz[0].categ;
      state.currentQuestion = state.quiz[0].id;
      state.loaderEnabled = false;

    });

    onUnmounted(() => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      window.removeEventListener('blur', handleWindowBlur);
      window.removeEventListener('focus', handleWindowFocus);
      
    });


    const nextQuestion = (currentId) => {
      state.quiz.filter(ref => ref.id == currentId)[0].display = 0;

      const currentIndex = state.quiz.findIndex(item => item.id === currentId);
      if (currentIndex !== -1 && currentIndex + 1 < state.quiz.length) {
        state.quiz[currentIndex + 1].display = 1;
        state.currentQuestion = state.quiz[currentIndex + 1].id;
        state.activeCateg = state.quiz[currentIndex + 1].categ;
      }
      testValid();
    }

    const final = (currentId) => {

      ElMessageBox.confirm('Souhaitez-vous valider et soumettre vos réponses au test ? Il ne sera plus possible de revenir sur le test.','Confirmation',
          { confirmButtonText: 'Soumettre le test', cancelButtonText: 'Annuler', type: 'warning'  }
      )
      .then( async () => {
        nextQuestion(currentId);
        await getAxios("/setFinal/" + props.idTest); 
        Swal.fire({title: "Merci à vous !",  text:'Nous allons analyser vos réponses et revenons vers vous dans quelques minutes. Veuillez patientez svp.', icon: "success", buttonsStyling: false, 
        confirmButtonText: t("Retour à l'accueil"),customClass: { confirmButton: "btn fw-bold btn-light-primary", }}).then(function () {emit("retour", 1);})
      })

    }

    const setReponse = async (idQuest, idReponse, reponse) => {
      await getAxios("/setReponse/" + idQuest + '/' + idReponse + '/' + (reponse ? 1:0));
    }
    


    const prevQuestion = (currentId) => {
      state.quiz.filter(ref => ref.id == currentId)[0].display = 0;

      const currentIndex = state.quiz.findIndex(item => item.id === currentId);
      if (currentIndex !== -1) {
        state.quiz[currentIndex - 1].display = 1;
        state.currentQuestion = state.quiz[currentIndex - 1].id;
        state.activeCateg = state.quiz[currentIndex - 1].categ;
      }
      testValid();
    }

    const testValid = () => {
      state.total_reponse = 0;
      state.quiz.forEach((val) => {
        val.valid = 0;
        val.reponses.forEach((rep) => {        
          if (rep.chxUser) {
            if (val.valid == 0) state.total_reponse ++;
            val.valid = 1;
          }
        })
      })
    }    

    const clickQuestion= (id) => {
      
      state.quiz.forEach((val) => {
        val.display = 0;
      })

      state.quiz.filter(ref => ref.id == id)[0].display = 1;
      state.currentQuestion = id;
      testValid();
    }    


    return {
      state,
      nextQuestion,
      prevQuestion,
      testValid,
      clickQuestion,
      final,
      setReponse
    };
  },
});

export async function getAxios(request: string): Promise<any> {
  const response = await mAxiosApi.get(request);
  return response.data;
}
