<template >

  <div class="resp-barre-rech" style="transition: all 0.15s ease-out; display: flex;place-content: center;align-items: flex-start;flex-direction: column; overflow: hidden" 
    :style="`padding: ${state.modeSearch == 'simple' ? '10%' : '0%'}; 
             height: ${state.modeSearch == 'simple' ? 'calc(100vh - 230px)' : 'initial'}; `" >

    <div :style="`filter: ${state.modeSearch == 'complex' && !state.isAuth  ? 'opacity(0.2)' : 'opacity(1)'}; 
                  height: ${state.modeSearch == 'complex' && !state.isAuth  ? 'calc(100vh - 40px)' : 'initial'};
                  background: ${state.modeSearch == 'simple' ? 'none' : 'rgb(255 255 255 / 27%)'};
    `" style="width: 100%; padding: 10px;" >
    
      <object class="objLogo resp-logo" data="media/still/Logo.svg" style="width: 50%; filter: drop-shadow(6px 8px 5px rgb(0 0 0 / 0.4));" :style="`display: ${state.modeSearch == 'simple' ? 'block' : 'none'}`"> </object>
      <div style="width: 100%">

        <template v-for="item in state.myList" :key="item.id">
          <div v-if="state.modeSearch=='simple'" class='btn btn-lg btn-primary' @click="state.currentTest=item.tst_seq; state.modeSearch='complex'">🚀 Lancer le test technique : {{ item.tst_libelle }}</div>
        </template>

        <Test v-if="state.modeSearch=='complex' && state.currentTest > 0" :idTest="state.currentTest" @retour="state.modeSearch='simple'"/>

      </div>
    </div>

  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, reactive } from "vue";
import store from "@/store";
import * as moment from "moment";
import mAxiosApi from "@/api";
import Test from "@/views/still/deploy/test.vue"
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { formatDateLang } from "@/App.vue";

export default defineComponent({
  name: "Overview",
  components: {
    Test,
  },
  setup() {

    const router = useRouter();

    //moment.default.locale("fr");
    const { t } = useI18n()
    const state = reactive({
      modeSearch : "simple",
      isAuth : store.getters.isUserAuthenticated,
      myList : [] as any,
      currentTest : 0,
    });

   

    const formatDate = (d) => {
      return moment.default(d).format(formatDateLang);
    };
    const formatDateAgo = (d) => {
      return moment.default().diff( d, 'days')*-1 + " " + t("jours");
    };

    onMounted(async () => {  
      const myList = await getAxios("/getTests");
      state.myList = myList.results;
      document.documentElement.requestFullscreen();
    });


    return {
      state,
      formatDate,
      formatDateAgo,
    };
  },
});

export async function getAxios(request: string): Promise<any> {
  const response = await mAxiosApi.get(request);
  return response.data;
}
</script>